import React, { useState } from "react";
import ReactDOM from "react-dom";

// Styles
import { Container } from "./HomePage.styles";
import { PrimaryButton } from "../Components.styles";

// Components

import Swiper from "../components/Swiper/Swiper";
import Pagination from "../components/Swiper/Pagination";
import ContentTextSection from "../components/ContentSection/ContentTextSection";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import Roadmap from "../components/Contact/Roadmap";
import Greetings from "../components/Greetings/Greetings";

// Images
import SlideImg1 from "../assets/images/Cosmic_Force_logo_COSMIC.jpg";
import SlideImg2 from "../assets/images/Pod.jpg";
import SlideImg3 from "../assets/images/Starfighter.jpg";

import foto1 from "../assets/images/Spaceship.png";
import foto2 from "../assets/images/Planetary_Kit_No_border.png";
import foto3 from "../assets/images/Outpost_Pod_no_Border_2.png";
import foto4 from "../assets/images/solar_system.jpg";
import foto5 from "../assets/images/leaderboard.jpg";
import foto6 from "../assets/images/marketplace.png";
import foto7 from "../assets/images/MagentaFragmentTeaser.jpg";

import foto8 from "../assets/images/CFX.png";
import foto9 from "../assets/images/ALPHA.png";
import foto10 from "../assets/images/OG.png";
import foto11 from "../assets/images/Green_1f_Planet.jpg";
import foto12 from "../assets/images/AI_CF.jpg";
import foto13 from "../assets/images/yellow_3.jpg";
import foto14 from "../assets/images/manufacturing.jpg";

import sim0 from "../assets/images/sim_0.jpg";
import sim1 from "../assets/images/sim_1.jpg";
import sim2 from "../assets/images/sim_2.jpg";
import sim3 from "../assets/images/sim_3.jpg";
import sim4 from "../assets/images/sim_4.jpg";
import sim5 from "../assets/images/sim_5.jpg";

import cosmic_node from "../assets/images/COSMIC_NODE_RELEASE.gif";
import cosmic_lite_node from "../assets/images/COSMIC_LITE-NODE_RELEASE.gif";
import cosmic_blockchain from "../assets/images/COSMIC_BLOCKCHAIN_SQ.png";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const HomePage = () => {
  const [showGreetings, setShowGreetings] = useState(false);
  const [swipeSlide, setSwipeSlide] = useState(0);


  const handleGreetings = () => {
    setShowGreetings((prev) => !prev);
  };

  const handleChangeSlide = (numb: number) => {
    setSwipeSlide(numb);
  };

  return (
    <Container variants={containerVariants} initial="hidden" animate="visible">
      <header className="hero">
        <Swiper swipeSlide={swipeSlide} slideImg1={SlideImg1} slideImg2={SlideImg2} slideImg3={SlideImg3} />
        <div className="hero__wrapper">

 
          <Pagination
            swipeSlide={swipeSlide}
            handleChangeSlide={handleChangeSlide}
          />
          <PrimaryButton data-aos="fade-up" data-aos-delay="600">
            <a href="https://discord.gg/jz5KvbEEuz" target="_blank" rel="cosmic">JOIN Cosmic Force</a>
          </PrimaryButton>
        </div>
      </header>
      <div className="videos-home" data-aos="fade-up" >
          <iframe style={{  width:'100%', height:'100%', border: '0'}} src="https://www.youtube.com/embed/_ETEI6FXnWE?autoplay=0&rel=0&modestbranding=1%frameborder=0" title="Cosmic Force intro video"  allowFullScreen></iframe>
      </div>
      <a id="intro" data-hs-anchor="true"></a>
      <div className="content-home" data-aos="fade-up" >
          <h2>COSMIC FORCE</h2>
          <h3>
          Play To Earn Metaverse Space Grand Strategy Exploration Game being developed for the Cosmic Blockchain
          </h3>
          <p>
          The Cosmic Force and WavemStudios team features expertise from different domains - blockchain, DeFi, game development, graphic design and cinematics. Bringing experience and creative ideas together, the team has what it takes to launch a AAA-level, Play-to-Earn, NFT game.
          </p>
          <p>
            &nbsp;
          </p>
          <p>
          We are developing and creating the Cosmic Force Metaverse to not only belong to developers but also the community.
          </p>
          <p>
            &nbsp;
          </p>
      </div>
      <ContentTextSection type="left" link="" linktext="" linktarget="_self" header1="COSMIC BLOCKCHAIN" header2="Cosmic Blockchain is part of WavemStudios and Cosmic Force" paragraph="A gaming blockchain should offer fast transactions and cheap transaction fees to allow game items to transfer swiftly between players. Many existing blockchains fall short on speed and cost so we decided it was time to build a dedicated blockchain for gaming, Cosmic Blockchain. The Cosmic Blockchain brings speed, security and low gas fees to the gaming world and its first gaming project Cosmic Force." foto1={cosmic_blockchain}/>
      <a id="story" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="" linktext="" linktarget="_self" header1="STORY SO FAR..." header2="Earth is now a faded memory" paragraph="As the path of evolution takes its form, transformations require great sacrifices, leaving behind everything. The Earth saga is now a faded memory in humanity’s history and survival. Humans are not the only interstellar lifeforms with the intention to endure the cosmos. In a finite universe resources and habitable planets have become a rarity. The current home planet needs now to colonize a further 6 identified planets to secure humanity’s future and defend against increasing alien attack. Cosmic Force Cadets are being recruited and trained to build the colony’s infrastructure, find resources, and also be the brave, fearless first line of planetary defense." foto1={foto4}/>
      <a id="gameplay" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="" linktext="" linktarget="_self" header1="GAMEPLAY" header2="Explore, Build and Earn NFT & $CFX Cosmic Force Token rewards" paragraph="Cosmic Force is an immersive role play first person grand strategy shooter exploration game (RPG+RTS+FPS) and will eventually become a massively multiplayer online (MMO) game. All players start out as Cosmic Force Cadets and go through basic training before getting their wings and being promoted to full fighter pilots. Cadet training includes in-game starfighter simulators, target practice, off-road all terrain races and near world real starfighter challenges. Once full training is complete you will choose one of the 6 colonies to travel to and start a new life, defending, building, manufacturing and mining whilst taking on specific missions. CORE, NFT and $CFX token rewards will be given for these tasks and successfully completed missions." foto1={foto1}/>
      <a id="trade" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="" linktext="" linktarget="_self" header1="TRADE AND INVEST" header2="Buy, Trade or create NFT passive income" paragraph="Use the Cosmic Force marketplace to Buy and Sell NFT digital assets. Everything from Bit-Cores to Starships and Mining Machinery. Purchase NFTs that can bring you passive income even if you don't play the game. Invest in items needed by the players like Transporters, Mining or Manufacturing Tools that create long term passive income in $CFX. $CFX can be used to buy other assets and can also be exchanged for real money." foto1={foto2}/>
      <a id="community" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://discord.gg/jz5KvbEEuz" linktext="Join Cosmic Force" linktarget="_blank" header1="COMMUNITY" header2="Building a strong Cosmic Force community" paragraph="We want to grow an engaging community of players through the Cosmic Blockchain. For a brand new blockchain to grow, it needs volume, which Cosmic Force will bring in as Cosmic Blockchain's first crypto based game. Multiplayer gaming is a fun and exciting way to interact with committed members of the community that also incentivises new players to the space. With this, Cosmic Force is essential for a new blockchain’s coin utility, building liquidity, and longevity." foto1={foto3}/>
      <a id="leaderboard" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="" linktext="" linktarget="_self" header1="LEADERBOARDS" header2="Play Win Earn" paragraph="During Cadet Training there will be daily and weekly tournament competitions. Leaderboards will be displayed within the Academy Training Facility tracking the top players. Digital NFT assets and the $CFX (Cosmic Force Token) will be rewarded to the winners." foto1={foto5}/>
      <a id="marketplace" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://marketplace.cosmicforce.xyz" linktext="Goto The Marketplace" linktarget="_self" header1="MARKETPLACE" header2="Trade NFT game assets with other players" paragraph="Buy, Sell, and Trade Cosmic Force NFT Digital Assets 24/7 with the online Marketplace. You can Sell your NFT GAME REWARDS and your purchased items earning $CFX which can be exchanged for REAL MONEY. The Cosmic Force Marketplace is community-driven and is one of the most popular ways of earning with your trading skills." foto1={foto6}/>
            
      <a id="sfx" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/cfxtoken" linktext="Buy Now" linktarget="_self" header1="$CFX COSMIC FORCE TOKEN" header2="Presale bonuses up to 50% extra on launch" paragraph="1 $CFX = 1 $BUSD and will have a value of up to $1.50 per $CFX once launched on BSC with added liquidity. Funds from game NFT sales will go towards $CFX liquidity, Cosmic Force Treasury and the Game Development Fund. Purchase an ALPHA or OG Cosmic Force Cadet NFT to remove and save the normal 5% $CFX purchase tax." foto1={foto8}/>
      <a id="alpha" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/alphatoken" linktext="Buy Now" linktarget="_self" header1="ALPHA NFT" header2="Presale 2,100 Alpha Cadet NFTs" paragraph="1 ALPHA NFT = 100 $BUSD. After launch 1 ALPHA will cost 100 $CFX and will be worth up to 50% extra. Owning the Alpha Cadet NFT grants you full Cadet access to Leaderboards and Rewards from the Cosmic Force Treasury allowing you to progress in the game. The Alpha Cadet NFT also represents additional digital assets including: Starfighter, Basic Avatar, Combat Suit, Planetary Kit, Outpost Pod all issued as individual extra NFTs once we launch on Cosmic Blockchain. You will also get an additional 25% OFF ALL NEW in-game digital NFT asset purchases FOREVER." foto1={foto9}/>
      <a id="og" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/ogtoken" linktext="Buy Now" linktarget="_self" header1="OG NFT" header2="Presale OG NFTs MAX SUPPLY 99" paragraph="OG price between 300 - 5,000 $BUSD. After launch any remaining OGs will be sold using $CFX costing up to 50% extra. The 99 OGs will receive all the same benefits as the Alpha Cadet NFTs but with extra bonuses. Additional 35% OFF ALL NEW in-game digital NFT asset purchases FOREVER. Customizable Avatar, custom starfighter skin with personal decal graphics." foto1={foto10}/>

      <a id="cores" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="/cores" linktext="Limited Presale ON NOW" linktarget="_self" header1="POWER CORE NFT" header2="Collect Trade Fuse Use" paragraph="Cores are used to BOOST the power of your suit, buggy, dune bike, jet ski, jet pack, starfighter, mining machinery and other larger vehicles to give you an EDGE over your opponents. All CORE NFTs have a $CFX VALUE and are tradable in the Cosmic Force marketplace. CORE colours are red, blue, green, yellow, cyan and magenta which correspond to the 6 colony planets and give a 25% POWER BOOST when used on the planet of origin. CORES are fused together to gain an extra 25% POWER BOOST and 25% EXTRA $CFX VALUE. CORES will ONLY be available to buy during the presale, once Cosmic Force goes live CORES will only be won as rewards. Trading existing CORES via the marketplace will also be possible. You can also use your ALPHA 25% or OG 35% discount in the POWER CORE Presale." foto1={foto7}/>

      <a id="planet" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Buy Now" linktarget="_self" header1="PLANET CO-OWNERSHIP NFT" header2="Buy Hold Reward" paragraph="Exclusive Co-Ownership of each of the 6 planets for early-stage investors. Passive income will come from ON-PLANET land purchases, mining revenue and manufacturing NFT sales. Planet Co-Owners will also receive a percentage income from ALL NEW in-game NFT sales from the Homeworld and Marketplace revenue. 60 PLANET 1% CO-OWNER and 300 0.1% CO-OWNER NFTs are on presale for 5000 $CFX and 750 $CFX each. This represents 10 x 1% CO-OWNER NFT per PLANET and 50 x 0.1% CO-OWNER NFT per PLANET. You can apply your ALPHA 25% or OG 35% discount plus the VALUE of up to 4 DUAL STACK COREs or PLATE COREs towards to final price. DUAL STACK CORE or PLATE CORE VALUE is deducted before OG or APLHA discount is applied." foto1={foto11}/>

      <a id="node" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/nodeowner" linktext="Buy Now" linktarget="_self" header1="COSMIC NODE NFT" header2="Buy Hold Reward" paragraph="300 COSMIC NODE NFTs are on presale. ALPHA 25% or OG 35% discount can be used ONLY when purchasing with $CFX. 1 Million COSMIC Coins will come into existence as rewards to node holders over a 5 year period starting from mainnet launch. Each COSMIC NODE NFT will receive 23.8 COSMIC Coins per MONTH plus a share of the COSMIC BLOCKCHAIN Gas Fees. Estimated COSMIC Coin release price between $1 - $3." foto1={cosmic_node}/>

      <a id="litenode" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/litenodeowner" linktext="Buy Now" linktarget="_self" header1="COSMIC LITE NODE NFT" header2="Buy Hold Reward" paragraph="300 COSMIC LITE NODE NFTs are on presale. ALPHA 25% or OG 35% discount can be used ONLY when purchasing with $CFX. 1 Million COSMIC Coins will come into existence as rewards to node holders over a 5 year period starting from mainnet launch. Each COSMIC LITE NODE NFT will receive 2.38 COSMIC Coins per MONTH plus a share of the COSMIC BLOCKCHAIN Gas Fees. Estimated COSMIC Coin release price between $1 - $3." foto1={cosmic_lite_node}/>

      <a id="mining" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="#mining" linktext="Launching Q1" linktarget="_self" header1="LAND DISTRIBUTION NFT" header2="Mining Manufacturing Residential" paragraph="Land claim NFTs will be available to purchase land on all 6 colony planets to individuals and LANDLORDS. Zones will be assigned for Mining, Manufacturing and Residential. Part of the revenue for purchases will go to the planet Co-Owners. If a player has no land of their own, they will be able to rent land from individuals or LANDLORDS. Value of land and surrounding territories will increase once rare minerals are found or building development has taken place." foto1={foto13}/>

      <a id="manufacture" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="#manufacture" linktext="Launching Q1" linktarget="_self" header1="MANUFACTURING BLUEPRINT NFT" header2="Create Enhance Improve" paragraph="Manufacturing Blueprint NFTs will be available for various in-game assets. With the Manufacturing Blueprint NFT you will be able to create new variations of the game's digital NFT asset represented by the Blueprint adding extra performance, customisations or rarities for resale as a new manufactured NFT on the Cosmic Force Marketplace. Blueprints will be available for a variety of assets including Starships, Weapons, Ammunition and Transportation vehicles." foto1={foto14}/>

      <a id="ai" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="" linktext="" linktarget="_self" header1="COSMIC FORCE AI" header2="Game Artificial Intelligence" paragraph="At Cosmic Force, we are always excited to experiment and discover new methods to incorporate the latest and greatest technology. By integrating AI technology into our games, we are able to provide players with a more immersive gaming experience that will blow your virtual rockets off! The best part is, we are not sacrificing any of the artistry or hand-crafted design that sets our game apart. We are blending cutting-edge technology with old-school game development techniques to serve up a fully seasoned experience. The use of AI also opens up new possibilities for deeper layers of player customization and personalization, allowing for a more tailored experience which is more accessible for everyone. Cosmic Force aims to push the boundaries and rules of gaming, and with AI and future technologies, now the potential here in gaming is truly limitless." foto1={foto12}/>


      <a id="sim" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="" linktext="" linktarget="_self" header1="SIMULATOR TRAINING" header2="Simulator Cadet Training Facility" paragraph="All Cosmic Force Cadets go through basic training before getting their wings and being promoted to full fighter pilots. Cadet training starts in the Cadet Simulator Training Facility. You will learn how to fly your starfighter using the Starfighter Simulator Module doing simulated missions to all 6 colony planets. Simulators will also be used for initial jet pack, jet ski, dune bike and dune buggy training before you are let loose on the in-game real Academy On Base Kit. Augmented reality will also be used for first person shooting challenges. All simulator and augmented reality missions will be reflected on leaderboards giving you NFT and $CFX rewards for top players." foto1={sim5}/>

      <Gallery image1={sim0} image2={sim2} image3={sim3} image4={sim4} image5={sim1}/>

      <Roadmap />

      <div className="videos-home-bottom" data-aos="fade-up" >
          <iframe style={{  width:'100%', height:'100%', border:'0'}} src="https://www.youtube.com/embed/fJ262cEyU90?autoplay=0&rel=0&modestbranding=1%frameborder=0" title="Cosmic Force intro video"  allowFullScreen></iframe>
      </div>
      <div className="videos-home-bottom" data-aos="fade-up" >
          <iframe style={{  width:'100%', height:'100%', border:'0'}} src="https://www.youtube.com/embed/HIwq30c8XxE?autoplay=0&rel=0&modestbranding=1%frameborder=0" title="Cosmic Force intro video"  allowFullScreen></iframe>
      </div>
      <div className="videos-home-bottom" data-aos="fade-up" >
          <iframe style={{  width:'100%', height:'100%', border:'0'}} src="https://www.youtube.com/embed/VHCCFZ7-gA0?autoplay=0&rel=0&modestbranding=1%frameborder=0" title="Cosmic Force intro video"  allowFullScreen></iframe>
      </div>

      <div className="ml-form-embed"
            data-account="1104702:o1r2r3b0u5"
            data-form="5872683:n8q8y2">
       </div>

      <Footer />
      {showGreetings &&
        ReactDOM.createPortal(
          <Greetings handleGreetings={handleGreetings} />,
          document.querySelector("#backdrop-root")!
        )}
    </Container>
  );
};

export default HomePage;
