import React from "react";
// Styles
import { Container, Fusepath } from "./Fusepath.styles";
// Images
import rightImage from "../../assets/images/Alpha_Suit_Cropped.png";
import leftImage from "../../assets/images/OG_Suit_Cropped.png";
import { Path12, Path13, Path14, Path15 } from "../../assets";

interface Props {
  image1: string;
}

const FusepathComponent: React.FC<Props> = ({ image1 }) => {
  return (
    <Container id="fusepath">
      <Fusepath>
        <img src={rightImage} alt="cosmic force" data-aos="fade-right" />
        <Path12 />
        <Path13 />
        <Path14 />
        <Path15 />
        <div className="fusepath_list">
        <img style={{  width:'100%', height:'100%'}} src={image1} alt="Home" useMap="#linkmap"></img>
        </div>
        <img src={leftImage} alt="cosmic force" data-aos="fade-left" />
      </Fusepath>
    </Container>
  );
};

export default FusepathComponent;
