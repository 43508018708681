import React from "react";
import { ContentButton } from "../../Components.styles";

// Styles
import { ContentTextSection } from "./ContentTextSection.styles";

const videoJsOptions = {
  sources: [
    {
      src: "../../assets/video/TestDrone.mp4",
      type: "video/mp4"
    }
  ]
};

interface Props {
  type: "left" | "right";
  header1: string;
  header2: string;
  paragraph: string;
  foto1: string;
  link: string;
  linktext: string;
  linktarget: string;
}


const ContentTextSectionComponent: React.FC<Props> = ({ type, header1, header2, paragraph, foto1, link, linktext, linktarget }) => {
  return (
    <ContentTextSection type={type} id="detail">  
  
        {type === "right" ? (
        <>
        <div className="content" data-aos="fade-up">
          <h2>{header1}</h2>
          <h3>
            {header2}
          </h3>
          <p>
            {paragraph}
          </p>
          <p>
            &nbsp;
          </p>
          {link !== "" ? (
            <>
              <p>
              <ContentButton data-aos="fade-in" data-aos-delay="600">
                <a href={link} target={linktarget} rel="cosmic">{linktext}</a>
              </ContentButton>
              </p>
              <p>
                &nbsp;
              </p>
            </>
             ) : (
            <>
              <p>
                &nbsp;
              </p>
            </>
          )}    
        </div>
        {linktarget === "GIF" ? (
          <>
          <div className="images" style={{background:'#b1b1b1'}} data-aos="fade-up">
            <img src={foto1} alt="image 1" />
          </div>
          </>
            ) : (
          <>
          <div className="images" data-aos="fade-up">
            <img src={foto1} alt="image 1" />
          </div>
          </>
          )}
        </>
      ) : (
        <>
        
        {linktarget === "GIF" ? (
          <>
          <div className="images" style={{background:'#b1b1b1'}} data-aos="fade-up">
            <img src={foto1} alt="image 1" />
          </div>
          </>
            ) : (
          <>
          <div className="images" data-aos="fade-up">
            <img src={foto1} alt="image 1" />
          </div>
          </>
          )}
        
        <div className="content" data-aos="fade-up">
        <h2>{header1}</h2>
        <h3>
          {header2}
        </h3>
        <p>
          {paragraph}
        </p>
        <p>
            &nbsp;
        </p>
        {link !== "" ? (
          <>
            <p>
            <ContentButton data-aos="fade-in" data-aos-delay="600">
              <a href={link} target={linktarget} rel="cosmic">{linktext}</a>
            </ContentButton>
            </p>
            <p>
              &nbsp;
            </p>
          </>
            ) : (
          <>
            <p>
              &nbsp;
            </p>
          </>
        )}
      </div>
      </>
      )}

    </ContentTextSection>
  );
};

export default ContentTextSectionComponent;
