import React from "react";

// Styles
import { Swiper } from "./Swiper.styles";


// Hooks
import useMediaQuery from "../../hooks/useMediaQuery";

interface Props {
  swipeSlide: number;
  slideImg1: string;
  slideImg2: string;
  slideImg3: string;


}

const SwiperComponent: React.FC<Props> = ({ swipeSlide, slideImg1, slideImg2, slideImg3 }) => {
  return (
    <Swiper swipeSlide={swipeSlide}>
      <div className="slide">
        <img src={slideImg1} alt="slide 1" />
      </div>
      <div className="slide">
        <img src={slideImg2} alt="slide 2" />
      </div>
      <div className="slide">
        <img src={slideImg3} alt="slide 3" />
      </div>
    </Swiper>
  );
};

export default SwiperComponent;
