import styled from "styled-components";

export const ContentSection = styled.section<{ type: "left" | "right" }>`
margin: ${({ type }) => (type === "left" ? "0 auto" : "0 auto 3rem")};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 92%;
  overflow: hidden;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  .videos {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    column-gap: 1rem;
    grid-row: 1/2;
    grid-column: ${({ type }) => (type === "left" ? 1 : 2)};
    margin-bottom: 5.6vh;

    color: #f00;
    text-align: center;

    width: 1280px;
    height: 720px;

    @media only screen and (max-width: 1910px) {
      width: 960px;
      height: 540px;
    }

    @media only screen and (max-width: 1670px) {
      width: 640px;
      height: 360px;
    }

    @media only screen and (max-width: 1270px) {
      grid-column: 1;
    }

    @media only screen and (max-width: 950px) {
      width: 420px;
      height: 240px;
      grid-column: 1;
    }

    @media only screen and (max-width: 480px) {
      width: 340px;
      height: 200px;
    }

    @media only screen and (max-width: 370px) {
      width: 300px;
      height: 170px;
    }

    img {
      width: 100%;
      height: auto;
      border: 1px solid var(--color-gray-3);

      &:nth-child(1) {
        grid-column: "1/3";
        grid-row: "3/-1";
      }
      &:nth-child(2) {
        grid-column: "3/-1";
        grid-row: "1/5";
      }
    }
  }

  .content {
   
    padding: 0 0.9rem 0 0.9rem;
    display: grid;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    grid-column: ${({ type }) => (type === "right" ? 1 : 2)};

    @media only screen and (max-width: 1200px) {
      grid-row: 2;
      grid-column: 1/-1;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 0.5rem 0 0.5rem;
    }
    @media only screen and (max-width: 600px) {
      width: auto;
    }

    h2 {
      font-size: 2.25rem;
      font-family: var(--font-heading);
      font-weight: bold;
      line-height: 2.5rem;
      color: var(--color-gray-6);
      margin-bottom: 1rem;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
        width: 300px;
      }
    }

    & > h3 {
      font-size: 1.50rem;
      font-family: var(--font-primary);
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: 2rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    p {
      font-size: 1.00rem;
      font-family: var(--font-primary);
      color: var(--color-gray-6);
      line-height: 1.25rem;

      @media only screen and (max-width: 600px) {
        font-size: 0.8125rem;
      }
    }

    .content__blocks {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;

      .block {
        height: 100px;
        width: 191px;

        @media only screen and (max-width: 600px) {
          width: 100%;
          height: auto;
        }
        h3 {
          font-size: 0.75rem;
          font-family: var(--font-heading);
          font-weight: bold;
          position: relative;
          padding-bottom: 0.3rem;
          color: var(--color-white);
          

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 10%;
            height: 2px;
            background: var(--color-white);
            z-index: 2;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: var(--color-gray-6);
          }
        }
        p {
          font-size: 0.875rem;
          color: var(--color-gray-6);
          padding-top: 1rem;
          font-family: var(--font-primary);

          @media only screen and (max-width: 600px) {
            width: 80%;
          }
        }
      }
    }
  }
`;
