import React, { useState } from "react";
import ReactDOM from "react-dom";

// Styles
import { Container } from "./CorePage.styles";

// Components
import Swiper from "../components/Swiper/Swiper";
import Pagination from "../components/Swiper/Pagination";
import ContentSection from "../components/ContentSection/ContentSection";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import Menu from "../components/Menu/Menu";
import Fusepath from "../components/Contact/Fusepath";
import Greetings from "../components/Greetings/Greetings";
import ContentTextSection from "../components/ContentSection/ContentTextSection";

// Images
import SlideImg1 from "../assets/images/GreenFragmentTeaser.jpg";
import SlideImg2 from "../assets/images/CyanFragmentTeaser.jpg";
import SlideImg3 from "../assets/images/RedFragmentTeaserFlipped.jpg";

import coreall from "../assets/images/CORES_ALL.jpg";
import coretruck from "../assets/images/truck_core.jpg";

import image1 from "../assets/images/REDCORE_768_OP_FULL.gif";
import image2 from "../assets/images/GREEN_PLATE_768_OP100.gif";
import image3 from "../assets/images/BLUE_STACK_768_60FRAMES_OPIMIZED.gif";
import image4 from "../assets/images/CYAN_768_OPFULL.gif";

//import image1 from "../assets/images/RED_FRAGMENT_BITCORE_540_LOW.gif";
//import image2 from "../assets/images/GREEN_PLATE_540_LOW.gif";
//import image3 from "../assets/images/BLUE_STACK_540_LOW.gif";
//import image4 from "../assets/images/FLAT_PACK_DUAL_STACK_540_LOW_CYAN.gif";

import fuse1 from "../assets/images/CORES_FUSE_PATH_V1_1200.jpg";
import fuse2 from "../assets/images/SINGLE_STACK_FUSINGPATH_V1_1200.jpg";
import fuse3 from "../assets/images/DUAL_STACK_FUSINGPATH_V2_1200.jpg";

const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const CorePage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showGreetings, setShowGreetings] = useState(false);
  const [swipeSlide, setSwipeSlide] = useState(0);

  const handleToggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleGreetings = () => {
    setShowGreetings((prev) => !prev);
  };

  const handleChangeSlide = (numb: number) => {
    setSwipeSlide(numb);
  };

  return (
    <Container variants={containerVariants} initial="hidden" animate="visible">
      <header className="hero">
        <Swiper swipeSlide={swipeSlide} slideImg1={SlideImg1} slideImg2={SlideImg2} slideImg3={SlideImg3} />
        <div className="hero__wrapper">
          <div className="hero__wrapper_info">
            <h1>
              <span>THE CORES</span>
            </h1>
          </div>
           <Pagination
            swipeSlide={swipeSlide}
            handleChangeSlide={handleChangeSlide}
          />
        </div>
      </header>
      <div className="videos-core" data-aos="fade-up" >
          <iframe style={{  width:'100%', height:'100%', border:'0'}} src="https://www.youtube.com/embed/ocQMOPizHmg?autoplay=0&rel=0&modestbranding=1%frameborder=0" title="Cosmic Force intro video"  allowFullScreen></iframe>
      </div>
      <div className="content-home" data-aos="fade-up" >
          <h2>COSMIC FORCE POWER CORES</h2>
          <h3>
          Collect Trade Fuse Use
          </h3>
          <p>
          Cores are used to BOOST the power of your suit, buggy, dune bike, jet ski, jet pack, starfighter, mining machinery, manufacturing equipment and other larger vehicles to give you an EDGE over your opponents.
          </p>
      </div>
      <div className="images-core" data-aos="fade-up" >
        <img style={{  width:'100%', height:'100%'}} src={coretruck} alt="Home" useMap="#linkmap"></img>
      </div>

      <div className="content-home" data-aos="fade-up" >
          <p>
          All CORE NFTs have a $CFX VALUE and are tradable in the Cosmic Force marketplace.
          </p>
          <p>
          CORE colours are red, blue, green, yellow, cyan and magenta which correspond to the 6 colony planets and give a 25% POWER BOOST when used on the planet of origin.
          </p>
          <p>
          CORES are fused together to gain an extra 25% POWER BOOST and 25% EXTRA $CFX VALUE.
          </p>
          <p>
          You can also use your ALPHA 25% or OG 35% discount in the POWER CORE Presale.
          </p>
          <p>
          $CFX TOTAL VALUE can be used to purchase future NFTs within COSMIC FORCE on launch plus you can ALSO use your ALPHA or OG discount at that point when buying that shiny new Mining Equipment or Starfighter.
          </p>
          <p>
          CORES give you endless POWER although a time limit will be set on MAXING out the CORE for extended periods.
          </p>
      </div>
      <div className="images-core" data-aos="fade-up" >
        <img style={{  width:'100%', height:'100%'}} src={coreall} alt="Home" useMap="#linkmap"></img>
      </div>
      <div className="content-home" data-aos="fade-up" >
          <p>
            <h3>
              CORES will ONLY be available to buy during the presale, once Cosmic Force goes live CORES will not be available to purchase and MINT, they can only be won as rewards.
            </h3>
          </p>    
          <p>
            <h3>
              FUSING existing CORES and TRADING via the marketplace will still be possible. 
            </h3>
          </p>    
      </div>

      <a id="startcores" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/bitcore" linktext="BUY NOW" linktarget="_self" header1="BITCORE 10,000 presale" header2="1 POWER UNIT 5 $CFX VALUE" paragraph="10,000 BITCORE NFTs are on presale for a special price of $4 (4 $CFX) but have a value of 5 $CFX. You can also use your ALPHA 25% or OG 35% discount. Single or multiple colour combinations can be used to FUSE 4 BITCOREs together making a PLATE CORE NFT worth 25 $CFX gaining an extra 25% POWER BOOST and 25% EXTRA $CFX VALUE." foto1={image1}/>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/platecore" linktext="BUY NOW" linktarget="_self" header1="PLATE CORE 1,000 presale" header2="5 POWER UNITS 25 $CFX VALUE" paragraph="1,000 PLATE CORE NFTs are on presale for $25 (25 $CFX). You can also use your ALPHA 25% or OG 35% discount.  FUSE 3 Single colour PLATE COREs together making a STACK CORE NFT  worth 100 $CFX gaining an extra 33.33% POWER BOOST and 33.33% EXTRA $CFX VALUE." foto1={image2}/>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/stackcore" linktext="BUY NOW" linktarget="_self" header1="STACK CORE 100 presale" header2="20 POWER UNITS 100 $CFX VALUE" paragraph="100 STACK CORE NFTs will go on presale for $100 (100 $CFX). You can also use your ALPHA 25% or OG 35% discount. Single or multiple colour combinations can be used to FUSE 2 STACK COREs together making a DUAL STACK CORE NFT worth 250 $CFX gaining an extra 25% POWER BOOST and 25% EXTRA $CFX VALUE." foto1={image3}/>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/dualstackcore" linktext="BUY NOW" linktarget="_self" header1="DUAL STACK CORE 50 presale" header2="50 POWER UNITS 250 $CFX VALUE" paragraph="50 DUAL STACK CORE NFTs will go on presale for $250 (250 $CFX). You can also use your ALPHA 25% or OG 35% discount. DUAL STACK COREs will give the extra boost required for heavy machinery, large starships, mining equipment and manufacturing. DUAL STACK COREs can boost and power multiple machinery items and equipment that are in a FIXED location." foto1={image4}/>

      <Fusepath image1={fuse1}/>
      <Fusepath image1={fuse2}/>
      <Fusepath image1={fuse3}/>
      
      <Footer />
      {ReactDOM.createPortal(
        <Menu showMenu={showMenu} toggleMenu={handleToggleMenu} />,
        document.querySelector("#backdrop-root")!
      )}
      {showGreetings &&
        ReactDOM.createPortal(
          <Greetings handleGreetings={handleGreetings} />,
          document.querySelector("#backdrop-root")!
        )}
    </Container>
  );
};

export default CorePage;
