import React, { useState } from "react";
// Hooks
import useMediaQuery from "../../hooks/useMediaQuery";
// Styles
import { Container, FullscreenImage, Backdrop } from "./Gallery.styles";

interface IshowImage {
  show: boolean;
  img: string | null;
}

interface Props {
  image1: string;
  image2: string;
  image3: string;
  image4: string;
  image5: string;
}

const Gallery: React.FC<Props> = ({ image1, image2, image3, image4, image5 }) => {
  const matches = useMediaQuery("(max-width: 900px)");
  const [showImage, setShowImage] = useState<IshowImage>({
    show: false,
    img: null,
  });

  const handleToggleShowImage = (img: string | null) => {
    setShowImage((prev) => ({
      show: !prev.show,
      img: img,
    }));
  };

  return (
    <>
      <Container id="gallery">
        <img
          onClick={() => handleToggleShowImage(image1)}
          data-aos="zoom-in"
          src={image1}
          alt="Cosmic Force"
        />
        <img
          onClick={() => {
            handleToggleShowImage(image2);
          }}
          data-aos="zoom-in"
          src={image2}
          alt="Cosmic Force"
        />
        <img
          onClick={() => {
            handleToggleShowImage(image3);
          }}
          data-aos="zoom-in"
          src={image3}
          alt="Cosmic Force"
        />
        <img
          onClick={() => {
            handleToggleShowImage(image4);
          }}
          data-aos="zoom-in"
          src={image4}
          alt="Cosmic Force"
        />
        <img
          onClick={() => {
            handleToggleShowImage(image5);
          }}
          data-aos="zoom-in"
          src={image5}
          alt="Cosmic Force"
        />
      </Container>
      {showImage.show && (
        <>
          <Backdrop
            onClick={() => {
              handleToggleShowImage(null);
            }}
          />
          <FullscreenImage
            onClick={() => {
              handleToggleShowImage(null);
            }}
            src={showImage.img!}
            alt="Full screen cosmic force image"
          />
        </>
      )}
    </>
  );
};

export default Gallery;
