import React, { useState } from "react";
import { AnimatePresence } from "framer-motion";

import {
  BrowserRouter as Router,
  Route,
  Link,
  Routes
} from "react-router-dom";

// Styles
import GlobalStyle from "./App.styles";
// Pages
import Appbar from "./components/Appbar/Appbar";
import HomePage from "./pages/HomePage";
import PlanetPage from "./pages/PlanetPage";
import CorePage from "./pages/CorePage";

import { Container } from "./Appbar.styles";


import AOS from "aos";

interface Props {
  toggleMenu: () => void;
}


function App() {
  AOS.init();

  return (
    <>
      <GlobalStyle />
      <AnimatePresence exitBeforeEnter>
        <Router>
          <Container>      
            <ul>
            
              <li>
                <a href="/" style={{ padding: '0px' }} ><img style={{ maxWidth: '60px' }} src="./images/CF_logo_75.png" alt="Home"></img></a>
              </li>
              <li>
                <Link to="/planets">Planets</Link>
              </li>
              <li>
                <Link to="/cores">Cores</Link>
              </li>
              <li>
                <a href="https://dapp.cosmicforce.xyz/cosmic" target="_self" rel="cosmic_force_dapp">dApp</a>
              </li>
              <li>
                <a href="https://marketplace.cosmicforce.xyz" target="_self" rel="cosmic_force_marketplace">Marketplace</a>
              </li>
            </ul>
          
          </Container>
          <Routes>
            <Route path="/" element={<HomePage />}></Route>  
            <Route path="/planets" element={<PlanetPage />}></Route>  
            <Route path="/cores" element={<CorePage />}></Route>  
          </Routes>
        </Router>
      </AnimatePresence>
    </>
  );
}

export default App;
