import React from "react";

// Icons
import { Car } from "../../assets";
import { ElectricStation } from "../../assets";

// Images
import foto1 from "../../assets/images/Red_1b_Planet.jpg";


// Styles
import { ContentSection } from "./ContentSection.styles";

interface Props {
  type: "left" | "right";
  header: string;
  video: string;
  stat1: string;
  stat2: string;
  stat3: string;
  stat4: string;
  stat5: string;
  stat6: string;
  stat7: string;
  stat8: string;
  stat9: string;
  stat10: string;
  stat11: string;
  stat12: string;
}

const ContentSectionComponent: React.FC<Props> = ({ type, header, video, stat1, stat2, stat3, stat4, stat5, stat6, stat7, stat8, stat9, stat10, stat11, stat12, }) => {
  return (

    <ContentSection type={type} id="planet statistics">  
  
        {type === "right" ? (
        <>
        <div className="content" data-aos="fade-up">
          <h2>STATISTICS {header}</h2>
          <div className="content__blocks">
            <div className="block">
              <h3>01</h3>
              <p>Diameter: {stat1}</p>
            </div>
            <div className="block">
              <h3>02</h3>
              <p>Number Of Moons: {stat2}</p>
            </div>
            <div className="block">
              <h3>03</h3>
              <p>Time To Turn on Axis: {stat3}</p>
            </div>
            <div className="block">
              <h3>04</h3>
              <p>Time To Orbit Star: {stat4}</p>
            </div>
            <div className="block">
              <h3>05</h3>
              <p>Size To Earth: {stat5}</p>
            </div>
            <div className="block">
              <h3>06</h3>
              <p>Surface Gravity: {stat6}</p>
            </div>
            <div className="block">
              <h3>07</h3>
              <p>Average Surface Temperature: {stat7}</p>
            </div>
            <div className="block">
              <h3>08</h3>
              <p>Most Habitable Zones: {stat8}</p>
            </div>
            <div className="block">
              <h3>09</h3>
              <p>Breathable Air: {stat9}</p>
            </div>
            <div className="block">
              <h3>10</h3>
              <p>Terrain: {stat10}</p>
            </div>
            <div className="block">
              <h3>11</h3>
              <p>Rare Minerals: {stat11}</p>
            </div>
            <div className="block">
              <h3>12</h3>
              <p>Core Minerals: {stat12}</p>
            </div>
          </div>
        </div>
        <div className="videos" data-aos="fade-up" >
        {video === "" ? (
          <>
          <h2>Awaiting Drone Signal</h2>
          </>
          ) : (
            <>
            <iframe style={{  width:'100%', height:'100%', position: 'relative', border: '0'}} src={`https://www.youtube.com/embed/${video}?autoplay=0&rel=0&modestbranding=1%frameborder=0`} title="Cosmic Force intro video"  allowFullScreen></iframe>
            </>
          )}
          </div>
            </>
        ) : (
        <>
          <div className="videos" data-aos="fade-up" >
          {video === "" ? (
            <>
            <h2>Awaiting Drone Signal</h2>
            </>
          ) : (
            <>
            <iframe style={{  width:'100%', height:'100%', position: 'relative', border: '0'}} src={`https://www.youtube.com/embed/${video}?autoplay=0&rel=0&modestbranding=1%frameborder=0`} title="Cosmic Force intro video"  allowFullScreen></iframe>
            </>
          )}
          </div>
          <div className="content" data-aos="fade-up">
          <h2>STATISTICS {header}</h2>
          <div className="content__blocks">
          <div className="block">
              <h3>01</h3>
              <p>Diameter: {stat1}</p>
            </div>
            <div className="block">
              <h3>02</h3>
              <p>Number Of Moons: {stat2}</p>
            </div>
            <div className="block">
              <h3>03</h3>
              <p>Time To Turn on Axis: {stat3}</p>
            </div>
            <div className="block">
              <h3>04</h3>
              <p>Time To Orbit Star: {stat4}</p>
            </div>
            <div className="block">
              <h3>05</h3>
              <p>Size To Earth: {stat5}</p>
            </div>
            <div className="block">
              <h3>06</h3>
              <p>Surface Gravity: {stat6}</p>
            </div>
            <div className="block">
              <h3>07</h3>
              <p>Average Surface Temperature: {stat7}</p>
            </div>
            <div className="block">
              <h3>08</h3>
              <p>Most Habitable Zones: {stat8}</p>
            </div>
            <div className="block">
              <h3>09</h3>
              <p>Breathable Air: {stat9}</p>
            </div>
            <div className="block">
              <h3>10</h3>
              <p>Terrain: {stat10}</p>
            </div>
            <div className="block">
              <h3>11</h3>
              <p>Rare Minerals: {stat11}</p>
            </div>
            <div className="block">
              <h3>12</h3>
              <p>Core Minerals: {stat12}</p>
            </div>
          </div>
        </div>
      </>
      )}
      </ContentSection>
  );
};

export default ContentSectionComponent;
