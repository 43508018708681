import React from "react";
// Styles
import { Container, Roadmap } from "./Roadmap.styles";
// Images
import rightImage from "../../assets/images/Alpha_Suit_Cropped.png";
import leftImage from "../../assets/images/OG_Suit_Cropped.png";
import { Path12, Path13, Path14, Path15 } from "../../assets";

const RoadmapComponent = ()  => {
  return (
    <Container id="roadmap">
      <Roadmap>
        <img src={rightImage} alt="cosmic force" data-aos="fade-right" />
        <Path12 />
        <Path13 />
        <Path14 />
        <Path15 />
        <div className="roadmap_list">
        <h2>COSMIC ROADMAP</h2>

        <h2>Q1 2023</h2>
          <p>
          Team Formation
          </p>
          <p>
          Market Research
          </p>
          <p>
          Presale $CFX Cosmic Force Token
          </p>
          <p>
          Presale Alpha Cadet NFTs and OG NFTs
          </p>
          <p>
          Start build
          </p>
          <h2>Q2 2023</h2>
          <p>
          Presale CORES NFTs
          </p>
          <h2>Q3 2023</h2>
          <p>
          Presale Planet Co-Ownership NFTs
          </p>
          <p>
          Gaming Blockchain Evaluation
          </p>
          <p>
          Announce COSMIC BLOCKCHAIN
          </p>
          <p>
          COSMIC NODE and LITE NODE launch
          </p>
          <p>
          Partnership with SparqNet and Avalanche
          </p>
          <h2>Q4 2023</h2>
          <p>
          Cosmic Blockchain TESTNET
          </p>
          <p>
          Pre-Alpha Cosmic Force game release with Simulator Training with limited base exploration
          </p>
          <p>
          Presale Basic Gameplay Cadet NFTs (Entitles gameplay rewards but NO discounts)
          </p>
          <h2>Q1 2024</h2>
          <p>
          Launch $CFX Liquidity Pair on BSC
          </p>
          <p>
          Presale COSMIC Coin
          </p>
          <p>
          Presale Land Mining Claim NFTs
          </p>
          <p>
          Presale Manufacturing Blueprint NFTs
          </p>
          <p>
          Launch leaderboards
          </p>
          <p>
          Cosmic Blockchain MAINNET
          </p>
          <p>
          Launch MINING rewards
          </p>
          <p>
          Launch NODE rewards
          </p>
          <p>
          Alpha Cosmic Force Game release with larger base exploration, missions and activities
          </p>
          <p>
          Launch Land Residential Plot NFTs
          </p>
          <h2>Q2 2024</h2>
          <p>
          Launch Multiplayer Gameplay
          </p>
          <p>
          $CFX listings on Centralized Exchanges CEX
          </p>
          <p>
          $COSMIC Coin listings on Centralized Exchanges CEX
          </p>
          <p>
          Launch EASY PLAYER SIGN-UP via Debit Card / Paypal solution
          </p>
          <h2>Q3 2024</h2>
          <p>
          Open beta for full openworld
          </p>
          <p>
          Launch Cosmic Gaming Developer Portal
          </p>
          <h2>Q4 2024</h2>
          <p>
          Openworld release
          </p>
          <p>
          Onboard 3rd party Game Studios onto Cosmic Blockchain
          </p>
          <p>
          &nbsp;
          </p>
          <ul>
          <li>
            <a href="https://whitepaper.cosmicforce.xyz/" target="_blank" rel="cosmic">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cosmic WhitePaper&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </a>
          </li>  
          </ul>   
        </div>
        <img src={leftImage} alt="cosmic force" data-aos="fade-left" />
      </Roadmap>
    </Container>
  );
};

export default RoadmapComponent;
