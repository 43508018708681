import React from "react";

import { HashLink as Link } from 'react-router-hash-link';
// Styles
import { Container } from "./Footer.styles";
// Icons
import {
  FacebookIcon,
  InstagramIcon,
  YoutubeIcon,
  LinkedinIcon,
  TwitterIcon,
} from "../../assets";


const Footer = () => {
  return (
    <Container>
      <div className="wrapper">
        <div className="socials">

          <div className="socials__icons">
            <a href="https://www.youtube.com/channel/UCq-Caghvu0KL91dKLKfL34w" target="_blank" rel="cosmic">
              <img  className="imgi"  src="./images/yt_final.png" alt="Nature"></img>
            </a>
            <a href="https://discord.gg/jz5KvbEEuz" target="_blank" rel="cosmic">
              <img  className="imgi" src="./images/discord_final.png" alt="Nature"></img>
            </a>
            <a href="https://t.me/+PzYpcR_vE3RiY2M8" target="_blank" rel="cosmic">
              <img  className="imgi"  src="./images/telegram_final.png" alt="Nature"></img>
            </a>
            <a href="https://medium.com/cosmic-force" target="_blank" rel="cosmic">
              <img  className="imgi"  src="./images/medium_final.png" alt="Nature"></img>
            </a>
            <a href="https://twitter.com/WavemStudios" target="_blank" rel="cosmic">
              <img  className="imgi" src="./images/twitter_final.png" alt="Nature"></img>
            </a>
          </div>
        </div>
        <div className="footer-links">
          <div className="footer-links__block">
            <span>Cosmic Force Game</span>
            <ul>
              <li>
                <Link to="/#intro">
                  Introduction
                </Link>
              </li>
              <li>
                <Link to="/#gameplay">
                  Gameplay
                </Link>
              </li>
              <li>
                <Link to="/#trade">
                  Trade and Invest
                </Link>
              </li>
              <li>
                <Link to="/#community">
                  Cosmic Force Community
                </Link>
              </li>
              <li>
                <Link to="/#leaderboard">
                  Cosmic Force Leaderboard
                </Link>
              </li>
              <li>
                <Link to="/#marketplace">
                  Cosmic Force Marketplace
                </Link>
              </li>
              <li>
                <Link to="/#sfx">
                  Cosmic Force Token
                </Link>
              </li>
              <li>
                <Link to="/#alpha">
                  Cosmic Force NFTs
                </Link>
              </li>
              <li>
                <Link to="/cores#startcores">
                  Power CORE NFTs
                </Link>
              </li>
              <li>
                <a href="https://dapp.cosmicforce.xyz/cfxtoken" target="_self" rel="cosmic">
                  Cosmic Force dApp
                </a>
              </li>  
              <li>
                <a href="https://whitepaper.cosmicforce.xyz/" target="_blank" rel="cosmic">
                  WhitePaper
                </a>
              </li>           
            </ul>
          </div>
          <div className="footer-links__block">
            <span>Planets</span>
            <ul>
              <li>
                <Link to="/planets#red-1b">
                  Red 1b
                </Link>
              </li>
              <li>
                <Link to="/planets#yellow-1c">
                  Yellow 1c
                </Link>
              </li>
              <li>
                <Link to="/planets#blue-1d">
                  Blue 1d
                </Link>
              </li>
              <li>
                <Link to="/planets#homeworld-1e">
                  Homeworld-1e
                </Link>
              </li>
              <li>
                <Link to="/planets#green-1f">
                  Green 1f
                </Link>
              </li>
              <li>
                <Link to="/planets#cyan-1g">
                  Cyan 1g
                </Link>
              </li>
              <li>
                <Link to="/planets#magenta-1h">
                  Magenta 1h
                </Link>
              </li>
            </ul>
          </div>   
          <div className="footer-links__block">
            <span>Community</span>
            <ul>
                <li>
                  <a href="/#" target="_self" rel="cosmic">
                    About Us
                  </a>
                </li>
                <li>
                <a href="https://medium.com/cosmic-force" target="_blank" rel="cosmic">
                    Latest News
                  </a>
                </li>
                <li>
                  <a href="https://www.youtube.com/channel/UCq-Caghvu0KL91dKLKfL34w" target="_blank" rel="cosmic">
                    YouTube
                  </a>
                </li>
                <li>
                  <a href="https://discord.gg/jz5KvbEEuz" target="_blank" rel="cosmic">
                    Discord
                  </a>
                </li>
                <li>
                  <a href="https://t.me/+PzYpcR_vE3RiY2M8" target="_blank" rel="cosmic">
                    Telegram
                  </a>
                </li>
                <li>
                  <a href="https://medium.com/cosmic-force" target="_blank" rel="cosmic">
                    Medium
                  </a>
                </li>
                <li>
                  <a href="https://twitter.com/WavemStudios" target="_blank" rel="cosmic">
                    Twitter
                  </a>
                </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="copyright-wrapper">
        <div className="copyright">&copy; WavemStudios 2023</div>
      </div>
    </Container>
  );
};

export default Footer;
