import styled from "styled-components";

export const ContentTextSection = styled.section<{ type: "left" | "right" }>`
  margin: ${({ type }) => (type === "left" ? "0 auto 1rem" : "0 auto 1rem")};
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  overflow: hidden;
  padding-left: 7.5rem;
  padding-right: 7.5rem;

  @media only screen and (max-width: 1200px) {
    grid-template-columns: 1fr;
  }

  @media only screen and (max-width: 900px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  @media only screen and (max-width: 600px) {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .images {
    display: grid;
    grid-template-rows: repeat(1, 1fr);
    grid-template-columns: repeat(1, 1fr);
    align-items: center;
    column-gap: 1rem;
    grid-row: 1/2;
    grid-column: ${({ type }) => (type === "left" ? 1 : 2)};
    margin-bottom: 1.6vh;

    @media only screen and (max-width: 1200px) {
      
      margin-bottom: 3rem;
      grid-row: 1;
      grid-column: 1/-1;
    }

    @media only screen and (max-width: 600px) {
      max-height: 500px;
    }

    img {
      width: 100%;
      height: auto;
      border: 0px solid var(--color-gray-1);

      &:nth-child(1) {
        grid-column: 1/3;
        grid-row: 3/-1;
      }
      &:nth-child(2) {
        grid-column: 1/5;
        grid-row: 2/5;
      }
    }
  }

  .content {
    width: 536px;
    display: grid;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    grid-column: ${({ type }) => (type === "right" ? 1 : 2)};

    @media only screen and (max-width: 1200px) {
      grid-row: 2;
      grid-column: 1/-1;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 0.5rem 0 0.5rem;
    }
    @media only screen and (max-width: 600px) {
      width: auto;
    }

    @media only screen and (max-width: 900px) {
      padding: 0 0rem 0 0rem;
    }

    h2 {
      font-size: 2.25rem;
      font-family: var(--font-heading);
      font-weight: bold;
      line-height: 2.5rem;
      color: var(--color-gray-6);
      margin-bottom: 1rem;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
        width: 300px;
      }

      @media only screen and (max-width: 350px) {
        font-size: 1.5rem;
        width: 230px;
      }
    }

    & > h3 {
      font-size: 1.50rem;
      font-family: var(--font-primary);
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: 2rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    p {
      font-size: 1.00rem;
      font-family: var(--font-primary);
      color: var(--color-gray-6);
      line-height: 1.75rem;

      @media only screen and (max-width: 600px) {
        font-size: 0.8125rem;
      }
    }

    .content__blocks {
      display: flex;
      flex-wrap: wrap;
      column-gap: 2rem;

      .block {
        height: 100px;
        width: 191px;

        @media only screen and (max-width: 600px) {
          width: 100%;
          height: auto;
        }
        h3 {
          font-size: 0.75rem;
          font-family: var(--font-heading);
          font-weight: bold;
          position: relative;
          padding-bottom: 0.3rem;
          color: var(--color-white);
          

          &::before {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 10%;
            height: 2px;
            background: var(--color-white);
            z-index: 2;
          }
          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 2px;
            background: var(--color-gray-6);
          }
        }
        p {
          font-size: 0.875rem;
          color: var(--color-gray-6);
          padding-top: 1rem;
          font-family: var(--font-primary);

          @media only screen and (max-width: 600px) {
            width: 80%;
          }
        }
      }
    }
  }
`;
