import styled from "styled-components";
import { motion } from "framer-motion";

export const Container = styled(motion.main)`
  width: 100%;
  overflow-x: hidden;

  .videos-core {

    margin:auto;
    display:block;

    width: 1920px;
    height: 1080px;

    margin-bottom: 5.6vh;

    @media only screen and (max-width: 1910px) {
      width: 1280px;
      height: 720px;
    }

    @media only screen and (max-width: 1270px) {
      width: 960px;
      height: 540px;
    }

    @media only screen and (max-width: 950px) {
      width: 640px;
      height: 360px;
    }

    @media only screen and (max-width: 630px) {
      width: 480px;
      height: 270px;
    }

    @media only screen and (max-width: 480px) {
      width: 370px;
      height: 210px;
    }

    @media only screen and (max-width: 370px) {
      width: 320px;
      height: 180px;
    }
  }

  .images-core {

    margin:auto;
    display:block;

    width: 1920px;
    

    margin-bottom: 1.6vh;

    @media only screen and (max-width: 1910px) {
      width: 1280px;
     
    }

    @media only screen and (max-width: 1270px) {
      width: 960px;
     
    }

    @media only screen and (max-width: 950px) {
      width: 640px;
     
    }

    @media only screen and (max-width: 630px) {
      width: 480px;
      
    }

    @media only screen and (max-width: 480px) {
      width: 370px;
    }

    @media only screen and (max-width: 370px) {
      width: 320px;
    }

  }

  .content-home {
    padding-left: 17.5rem;
    padding-right: 17.5rem;
    width: auto;
    display: grid;
    align-items: center;
    align-content: center;
    margin: 0 auto;
    grid-column: 1;
    margin-bottom: 1.6vh;

    @media only screen and (max-width: 1700px) {
      padding-left: 12rem;
      padding-right: 12rem;
    }

    @media only screen and (max-width: 1200px) {
      grid-row: 2;
      grid-column: 1/-1;
      padding-left: 5rem;
      padding-right: 5rem;
    }

    @media only screen and (max-width: 900px) {
      padding-left: 2.5rem;
      padding-right: 2.5rem;
    }
    @media only screen and (max-width: 600px) {
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }

    h2 {
      width: 500px;
      font-size: 2.25rem;
      font-family: var(--font-heading);
      font-weight: bold;
      line-height: 2.5rem;
      color: var(--color-gray-6);
      margin-bottom: 1rem;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
        width: 300px;
      }
    }

    & > h3 {
      font-size: 1.50rem;
      font-family: var(--font-primary);
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: 1rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    p {
      font-size: 1.00rem;
      font-family: var(--font-primary);
      color: var(--color-gray-6);
      line-height: 1.75rem;

      @media only screen and (max-width: 600px) {
        font-size: 0.8125rem;
      }
    }
  }

  .hero {
    position: relative;
    width: 100%;
    margin-bottom: 5.6vh;

    &::before {
      content: "";
      width: 50%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: transparent
        linear-gradient(90deg, var(--color-black) 0%, #00000000 100%) 0% 0%
        no-repeat padding-box;
      opacity: 0.75;
      z-index: 2;
    }

    &__wrapper {
      width: 70%;
      margin: 0 auto;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      height: 100%;
      z-index: 1;
      display: grid;
      align-items: center;
      justify-items: center;
      background: transparent;
      z-index: 3;

      @media only screen and (max-width: 900px) {
        width: 80%;
      }

      @media only screen and (max-width: 600px) {
        width: 85%;
      }

      &_info {
        color: #fff;
        justify-self: start;

        @media only screen and (max-width: 600px) {
          align-self: start;
        }

        & > h1 {
          font-size: 4rem; // 64px
          font-family: var(--font-heading);

          @media only screen and (max-width: 600px) {
            font-size: 3rem; // 48px
            line-height: 4rem;

            span {
              display: block;
            }
          }
        }
        & > h2 {
          font-size: 1rem;
          font-family: var(--font-heading);
          font-weight: 600;

          @media only screen and (max-width: 600px) {
            font-weight: lighter;
            color: #d6d6d6;
          }
        }
      }
    }
  }
`;
