import React, { useState } from "react";
import ReactDOM from "react-dom";

// Styles
import { Container } from "./PlanetPage.styles";

// Components
import Swiper from "../components/Swiper/Swiper";
import Pagination from "../components/Swiper/Pagination";
import ContentSection from "../components/ContentSection/ContentSection";
import ContentTextSection from "../components/ContentSection/ContentTextSection";
import Gallery from "../components/Gallery/Gallery";
import Footer from "../components/Footer/Footer";
import Menu from "../components/Menu/Menu";
import Greetings from "../components/Greetings/Greetings";

// Images
import SlideImg1 from "../assets/images/Magenta_1h_Planet_wide.jpg";
import SlideImg2 from "../assets/images/HomeWorld_1e_Planet_wide.jpg"; 
import SlideImg3 from "../assets/images/Blue_1d_Planet_wide.jpg";

import PlanetsImage from "../assets/images/solar_system.jpg";

import planet1b from "../assets/images/Red_1b_Planet.jpg";
import planet1c from "../assets/images/Yellow_1c_Planet.jpg";
import planet1d from "../assets/images/Blue_1d_Planet.jpg";
import planet1e from "../assets/images/HomeWorld_1e_Planet.jpg";
import planet1f from "../assets/images/Green_1f_Planet.jpg";
import planet1g from "../assets/images/Cyan_1g_Planet.jpg";
import planet1h from "../assets/images/Magenta_1h_Planet.jpg";

import red1 from "../assets/images/red_1.jpg";
import red2 from "../assets/images/red_2.jpg";
import red3 from "../assets/images/red_3.jpg";
import red4 from "../assets/images/red_4.jpg";
import red5 from "../assets/images/red_5.jpg";

import blue1 from "../assets/images/blue_1.jpg";
import blue2 from "../assets/images/blue_2.jpg";
import blue3 from "../assets/images/blue_3.jpg";
import blue4 from "../assets/images/blue_4.jpg";
import blue5 from "../assets/images/blue_5.jpg";


import cyan1 from "../assets/images/cyan_1.jpg";
import cyan2 from "../assets/images/cyan_2.jpg";
import cyan3 from "../assets/images/cyan_3.jpg";
import cyan4 from "../assets/images/cyan_4.jpg";
import cyan5 from "../assets/images/cyan_5.jpg";

import green1 from "../assets/images/green_1.jpg";
import green2 from "../assets/images/green_2.jpg";
import green3 from "../assets/images/green_3.jpg";
import green4 from "../assets/images/green_4.jpg";
import green5 from "../assets/images/green_5.jpg";

import home1 from "../assets/images/home_1.jpg";
import home2 from "../assets/images/home_2.jpg";
import home3 from "../assets/images/home_3.jpg";
import home4 from "../assets/images/home_4.jpg";
import home5 from "../assets/images/home_5.jpg";

import yellow1 from "../assets/images/yellow_1.jpg";
import yellow2 from "../assets/images/yellow_2.jpg";
import yellow3 from "../assets/images/yellow_3.jpg";
import yellow4 from "../assets/images/yellow_4.jpg";
import yellow5 from "../assets/images/yellow_5.jpg";

import magenta1 from "../assets/images/magenta_1.jpg";
import magenta2 from "../assets/images/magenta_2.jpg";
import magenta3 from "../assets/images/magenta_3.jpg";
import magenta4 from "../assets/images/magenta_4.jpg";
import magenta5 from "../assets/images/magenta_5.jpg";


const containerVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      duration: 0.5,
    },
  },
};

const PlanetPage = () => {
  const [showMenu, setShowMenu] = useState(false);
  const [showGreetings, setShowGreetings] = useState(false);
  const [swipeSlide, setSwipeSlide] = useState(0);

  const handleToggleMenu = () => {
    setShowMenu((prev) => !prev);
  };

  const handleGreetings = () => {
    setShowGreetings((prev) => !prev);
  };

  const handleChangeSlide = (numb: number) => {
    setSwipeSlide(numb);
  };

  return (
    <Container variants={containerVariants} initial="hidden" animate="visible">
      <header className="hero">
        <Swiper swipeSlide={swipeSlide} slideImg1={SlideImg1} slideImg2={SlideImg2} slideImg3={SlideImg3} />
        <div className="hero__wrapper">
          <div className="hero__wrapper_info">
            <h1>
              <span>THE PLANETS</span>
            </h1>
          </div>
           <Pagination
            swipeSlide={swipeSlide}
            handleChangeSlide={handleChangeSlide}
          />
        </div>
      </header>
      <div className="videos-planet" data-aos="fade-up" >
          <img style={{  width:'100%', height:'100%'}} src={PlanetsImage} alt="Home" useMap="#linkmap"></img>
      </div>
      <div className="content-home" data-aos="fade-up" >
          <h2>COSMIC FORCE METAVERSE</h2>
          <h3>
          The Cosmic Force Metaverse is the Solar System TRAPPIST 1 with Red Dwarf 1a
          </h3>
          <p>
          The star of the TRAPPIST-1 system is a red ultra-cool dwarf, the least massive type of star. In contrast the earth's sun, the TRAPPIST-1 star is so cool that liquid water exists on planets orbiting very close to it. This star also shines brightest in infrared wavelengths, which are invisible to the human eye.
          </p>
          <p>
            &nbsp;
          </p>
          <p>
          The TRAPPIST-1 system contains a total of seven planets. While Red-1b, Yellow-1c, Cyan-1g  and Magenta-1h require life support environments to be in place the remaining three planets have the right conditions to harbor life without support. Rare minerals and rewards are available on ALL planets but the most rare could be on the inner and outer.
          </p>
          <p>
            &nbsp;
          </p>
          <h2>PLANET OWNERSHIP</h2>
          <p>
          Exclusive Co-Ownership of each of the 6 Cosmic Force planets for early-stage investors. Passive income will come from ON-PLANET land purchases, mining revenue and manufacturing NFT sales. Planet Co-Owners will also receive a percentage income from ALL NEW in-game NFT sales from the Homeworld and Marketplace revenue.
          </p>
          <p>
            &nbsp;
          </p>
      </div>
      <a id="red-1b" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Red - 1b" header2="Distance from Star: 1069000 miles" paragraph="Due to the immense tidal forces and orbiting so close to 1b’s host star TRAPPIST-1a, planet 1b remained a super hot lava planet with seas and lakes of lava after its formation. The gravity from the nearby star distorts the planet periodically, with the resulting friction producing internal heat. The intense stellar irradiation also melts the surface crust directly into lava during its day, and even causes lava rain to fall on its night by the condensation of vaporized rock." foto1={planet1b}/>
      <Gallery image1={red1} image2={red2} image3={red3} image4={red4} image5={red5}/>
      <ContentSection type="left" header="Red - 1b"video="hHqbqbrtVsU" stat1="Diameter: 8876 miles" stat2="None" stat3="29 hours" stat4="1.5 days" stat5="1.12" stat6="0.812" stat7="477°C" stat8="Poles" stat9="No" stat10="Rocky, global volcanic mountains and lava lakes" stat11="Palladium, Rhodium" stat12="Roxotemo"/>
      
      <a id="yellow-1c" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Yellow - 1c" header2="Distance from Star: 1469000" paragraph="Just like 1b, being so close to its host star, planet 1c has no existing water. The thick atmosphere could be described as Venus-like however with a much lower temperature and a faster day and night cycle. Being a rocky type terrain, surface outposts are possible while keeping caution for violent winds and the vast amounts of geological activity present due to the proximity to star orbit. However floating settlements could be made in the upper-middle atmosphere above the clouds using balloons filled with breathable air." foto1={planet1c}/>
      <Gallery image1={yellow1} image2={yellow2} image3={yellow3} image4={yellow4} image5={yellow5}/>
      <ContentSection type="right" header="Yellow - 1c" video="Y0uehAX-s9A" stat1="8718 miles" stat2="2 Moons (1301+1138 miles diameter)" stat3="71 hours" stat4="2.4 days" stat5="1.1" stat6="0.966" stat7="61.7°C" stat8="High altitude/ ground level at poles" stat9="No" stat10="Rocky, mountains, dunes, plains" stat11="Rhenium, Diamond" stat12="Cratia"/>
      
      <a id="blue-1d" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Blue - 1d" header2="Distance from Star: 2139000" paragraph="Being mostly a global ocean, planet 1d has the most abundance of water in the TRAPPIST system. Hurricanes are a regular occurrence resulting in giant waves reaching 50 metres tall. Most animal life inhabit the planet's ocean, with rare instances of life traversing the surface. The sparse selection of surface features are volcanic islands with thick jungle." foto1={planet1d}/>
      <Gallery image1={blue1} image2={blue2} image3={blue3} image4={blue4} image5={blue5}/>     
      <ContentSection type="left" header="Blue - 1d" video="Zm1sTXKvT0M" stat1="6260 miles" stat2="None" stat3="24 hours" stat4="4 days" stat5="0.79" stat6="0.483" stat7="9°C" stat8="Coastal areas" stat9="Yes" stat10="Global oceans, deep ocean trenches, volcanic islands with thick jungle" stat11="Neodymium, Gold" stat12="Ritosinate"/>
      
      <a id="homeworld-1e" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="" linktext="" linktarget="_self" header1="HomeWorld - 1e" header2="Distance from Star: 2734000" paragraph="The homeworld, planet 1e, is the first and only planet colonized by earth's 2nd expedition colony on the one-way journey to find new habitable worlds after Earth's destruction. 1e is tidally locked to its star, with one side permanently facing the sun and the other side in perpetual darkness. Each face maintains extreme temperatures, creating a terminator line that supports life. This twilight zone reaches around 1e’s circumference The super hot air from the sun facing side melts the ice from the opposite side which enables rivers and lakes to exist on the terminator line, in turn allowing vegetation and animals to evolve between the two extremes of temperature." foto1={planet1e}/>
      <Gallery image1={home1} image2={home2} image3={home3} image4={home4} image5={home5}/>
      <ContentSection type="right" header="HomeWorld - 1e" video="dFzd78YSABU" stat1="7290 miles" stat2="1 Moon (1583 miles diameter)" stat3="141 hours" stat4="6 days" stat5="0.92" stat6="0.93" stat7="−27.1°C" stat8="Terminator line" stat9="Yes" stat10="Rocky, mountains, canyons, dunes, plains, forests, lakes, giant ice sheets" stat11="Silver, Titanium" stat12="All 6 Core Minerals"/>
      
      <a id="green-1f" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Green - 1f" header2="Distance from Star: 3580000" paragraph="The last of the planets in the habitable zone is planet 1f. This mostly green planet has the distinctive “Eye” that makes up the largest ocean on 1f. The giant body of water surrounded by landmass, creating the effect of an eyeball. Although much of the planet is below freezing point, the planet’s crust is riddled with hydrothermal activity resulting in hot springs and geysers. This difference in temperature has most animal and plant life surrounding these areas." foto1={planet1f}/>
      <Gallery image1={green1} image2={green2} image3={green3} image4={green4} image5={green5}/>
      <ContentSection type="left" header="Green - 1f" video="0h2OFKgnai8" stat1="8322 miles" stat2="None" stat3="10 hours" stat4="9 days" stat5="1.05" stat6="0.62" stat7="−54°C" stat8="Equator" stat9="Yes" stat10="Rocky, disconnected oceans, mountains, hills, grassland, forests, geysers glaciers and icefields" stat11="Iridium, Yttrium" stat12="Phenopa"/>
      
      <a id="cyan-1g" data-hs-anchor="true"></a>
      <ContentTextSection type="left" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Cyan - 1g" header2="Distance from Star: 4361000" paragraph="Planet 1g is a rusted planet. The whole surface top layer is made up of primarily iron and over millions of years oxygen has been absorbed into the crust, resulting in the surface’s distinctive colour. Due to the planet's depreciating magnetic field and further distance from the red dwarf star, receiving less light, auroras have become common in the upper atmosphere globally. Liquid water can still be found on the surface within a small selection of lakes on the equator, with most lakes being frozen." foto1={planet1g}/>
      <Gallery image1={cyan1} image2={cyan2} image3={cyan3} image4={cyan4} image5={cyan5}/>
      <ContentSection type="right" header="Cyan - 1g" video="hEro2Sslmpk" stat1="8956 miles" stat2="3 Moons (1128 + 1002 + 820 miles diameter)" stat3="13 hours" stat4="12 days" stat5="1.13" stat6="0.871" stat7="−78.65°C" stat8="Equator" stat9="No" stat10="Rocky, mountains, valleys, plains, craters, lakes, frozen lakes, icefields" stat11="Osmium, Platinum" stat12="Diadina"/>
      
      <a id="magenta-1h" data-hs-anchor="true"></a>
      <ContentTextSection type="right" link="https://dapp.cosmicforce.xyz/planetowner" linktext="Co-Owner NFT Presale BUY NOW" linktarget="_self" header1="Magenta - 1h" header2="Distance from Star: 5756000" paragraph="The furthest planet from its host star is planet 1h. It sets out to be the most haunting planet in the TRAPPIST system, from receiving the least amount of starlight, almost reaching the frost line, harboring ice volcanoes, retaining a minimal atmosphere, and having a possibly frozen core. The surface holds a lot of verticality due to its cryovolcanism, pushing up the rocky terrain. Although this seismic activity could suggest sub-surface areas of liquid water." foto1={planet1h}/>
      <Gallery image1={magenta1} image2={magenta2} image3={magenta3} image4={magenta4} image5={magenta5}/>
      <ContentSection type="left" header="Magenta - 1h" video="jpIfU3oVpu4" stat1="6022 miles" stat2="None with rings (4526 miles outer ring radius)" stat3="17 hours" stat4="19 days" stat5="0.76" stat6="0.555" stat7="−104°C" stat8="Equator" stat9="No" stat10="Rocky, mountains, valleys, craters, cryovolcanoes" stat11="Cesium, Tantalum" stat12="Maganax"/>
      
      <Footer />
      {ReactDOM.createPortal(
        <Menu showMenu={showMenu} toggleMenu={handleToggleMenu} />,
        document.querySelector("#backdrop-root")!
      )}
      {showGreetings &&
        ReactDOM.createPortal(
          <Greetings handleGreetings={handleGreetings} />,
          document.querySelector("#backdrop-root")!
        )}
    </Container>
  );
};

export default PlanetPage;
