import styled from "styled-components";

export const Container = styled.div`
  position: relative;
  width: 100%;
  margin: 3rem auto 0;
  display: grid;
  place-items: center;
  overflow: hidden;
`;

export const Roadmap = styled.nav`
  max-width: 500px;
  box-shadow: 3px 3px 60px #00000012;
  display: grid;
  background: var(--color-white);
  position: relative;
  margin: 5rem 0 10rem 0;

  @media only screen and (max-width: 600px) {
    box-shadow: none;
    margin: 20rem 0 25rem 0;
  }

  svg {
    position: absolute;
    z-index: 4;

    &:nth-of-type(1) {
      left: -350px;
      bottom: 175px;
    }
    &:nth-of-type(2) {
      left: -150px;
      top: 175px;
    }
    &:nth-of-type(3) {
      right: -300px;
      top: 200px;
      transform: rotate(250deg);
    }
    &:nth-of-type(4) {
      right: 50px;
      bottom: -125px;
      transform: rotate(-0deg);
    }
  }

  img {
    position: absolute;
    z-index: 5;

    &:nth-of-type(1) {
      right: -300px;
      top: 150px;

      @media only screen and (max-width: 600px) {
        left: -200px;
        top: -350px;
        width: 550px;
      }
    }
    &:nth-of-type(2) {
      left: -300px;
      bottom: 0;

      @media only screen and (max-width: 600px) {
        left: 30%;
        bottom: -350px;
        width: 550px;
      }
    }
  }

  & > .roadmap__header {
    position: relative;
    z-index: 10;
    background-color: var(--color-white);
    padding: 4rem 4rem 2rem 4rem;

    @media only screen and (max-width: 600px) {
      padding: 0rem 2rem 1rem 2rem;
    }

    h2 {
      font-size: 2.25rem;
      font-family: var(--font-heading);
      font-weight: bold;
      line-height: 2.5rem;
      color: var(--color-gray-6);
      margin-bottom: 1rem;
      margin-top: 1rem;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
        width: 300px;
      }
    }

    & > h3 {
      font-size: 1.50rem;
      font-family: var(--font-primary);
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: 2rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    p {
      font-size: 1.00rem;
      font-family: var(--font-primary);
      color: var(--color-gray-6);
      line-height: 1.25rem;

      @media only screen and (max-width: 600px) {
        font-size: 0.8125rem;
      }
    }
  }

  & > .roadmap_list {
    position: relative;
    z-index: 10;
    background-color: rgb(0 0 0 / 85%);
    padding: 2rem 4rem 4rem 4rem;

    @media only screen and (max-width: 600px) {
      padding: 1rem 2rem 2rem 2rem;
    }

    h2 {
      font-size: 2.25rem;
      font-family: var(--font-heading);
      font-weight: bold;
      line-height: 2.5rem;
      color: var(--color-gray-6);
      margin-bottom: 0.5rem;
      margin-top: 1rem;

      @media only screen and (max-width: 900px) {
        font-size: 1.5rem;
        width: 200px;
      }
    }

    & > h3 {
      font-size: 1.50rem;
      font-family: var(--font-primary);
      font-weight: 300;
      color: var(--color-white);
      margin-bottom: 2rem;

      @media only screen and (max-width: 600px) {
        margin-bottom: 1rem;
        font-size: 1rem;
      }
    }

    p {
      font-size: 1.00rem;
      font-family: var(--font-primary);
      color: var(--color-gray-6);
      line-height: 2.25rem;

      @media only screen and (max-width: 600px) {
        font-size: 0.8125rem;
      }
    }


  }
`;
